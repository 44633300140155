import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Form,
  Button,
  Input,
  Spin,
  notification,
  Alert,
  Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  fetchCreateSupplierInvoice,
  fetchSupplierInvoiceByUniqueId,
  fetchSupplierInvoiceItemsbyVendor,
  fetchSendCartItemsToInvoice,
  fetchRecordedItemsbyVendor,
  fetchUpdate,
} from '../../redux/supplierInvoiceSlice';

import { fetchUploadUrl } from '../../redux/productSlice';
import { extractInvoiceItem } from '../../helpers';
import { EditInvoiceItem, FileViewerUploader } from '../../components';
import './EditInvoice.css';

function EditInvoice() {
  const { t } = useTranslation('translation');
  const navigate = useNavigate();

  const { Option } = Select;
  const { user } = useSelector((state) => state.user);
  const { vendor } = useSelector((state) => state.vendor);
  const { handle } = useParams();

  const [loading, setLoading] = useState(false);
  const [isInvoiceDataFetched, setisInvoiceDataFetched] = useState(false);
  const [isCartItemDataFetched, setIsCartItemDataFetched] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [recordedCartItemIDs, setRecordedCartItemIDs] = useState([]);
  const [invoiceCartItems, setInvoiceCartItems] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [code, setCode] = useState('');
  const [sort, setSort] = useState(-1);
  const [form] = Form.useForm();

  const handleFileListChange = (updatedFileList) => {
    setFileList(updatedFileList);
  };

  const handleUploadImages = async () => {
    const fileLinks = [];
    await Promise.all(
      fileList.map(async (file, index) => {
        if (file.file) {
          const response = await fetchUploadUrl(`supplier-invoice/${code}/${index}`, file?.file?.type);
          await fetch(response.data, {
            method: 'PUT',
            body: file?.file,
          });

          const fileUrl = response.data.split('?')[0];
          fileLinks.push({ url: fileUrl, type: file?.file?.type });
        } else {
        // File already available, use the existing URL
          fileLinks.push({ url: file.preview, type: file?.type });
        }
      }),
    );
    return fileLinks;
  };

  const handleAddProductToInvoice = (selectedItem) => {
    const selectedInvoiceItem = extractInvoiceItem(selectedItem);
    const updatedCartItems = cartItems.map((item) => {
      if (item.cart_item?._id === selectedItem?.cart_item?._id) {
        return {
          ...item,
          blacklist: true,
        };
      }
      return item;
    });

    setInvoiceCartItems([...invoiceCartItems, selectedInvoiceItem]);
    setCartItems(updatedCartItems);
  };

  const handleRemoveProductFromInvoice = (selectedItem) => {
    const updatedInvoiceCartItems = invoiceCartItems.filter(
      (item) => item._id !== selectedItem?._id,
    );
    const updatedCartItems = cartItems.map((item) => {
      if (item.cart_item?._id === selectedItem?._id) {
        return {
          ...item,
          blacklist: false,
        };
      }
      return item;
    });
    setInvoiceCartItems(updatedInvoiceCartItems);
    setCartItems(updatedCartItems);
  };

  const handleSubmit = async () => {
    if (handle !== 'new' || code) {
      const fileLinks = await handleUploadImages();
      await fetchSendCartItemsToInvoice(code, invoiceCartItems, fileLinks)
        .then(() => {
          notification.success({
            message: t('errors.Modify successful'),
          });
        })
        .catch((err) => {
          notification.error({
            message: err?.response?.data?.message,
          });
        });
    } else {
      if (!invoiceCartItems || invoiceCartItems.length === 0) {
        notification.error({
          message: t('errors.Add Order Products to Create Invoice'),
        });
        return;
      }
      fetchCreateSupplierInvoice(invoiceCartItems, vendor?._id).then(async (res) => {
        if (res.status === 200) {
          setCode(res?.data?.unique_supplier_invoice_id);
          form.setFieldsValue({ code: res?.data?.unique_supplier_invoice_id });
          notification.success({
            message: t('errors.Invoice created successfully'),
          });
          navigate(-1);
        } else {
          notification.error({
            message: t('errors.Invoice couldn\'t created successfully'),
          });
        }
      });
    }
  };

  useEffect(() => {
    if (handle !== 'new' && !isInvoiceDataFetched) {
      fetchSupplierInvoiceByUniqueId(handle)
        .then((res) => {
          setInvoiceCartItems(res?.data?.invoice_item);
          const files = res?.data?.images?.map((file) => ({
            file: null,
            preview: file?.url,
            type: file?.type,
          }));

          setFileList(files);
          setisInvoiceDataFetched(true);
        })
        .catch((error) => {
          console.warn(error);
        });
    }
  }, [handle]);

  useEffect(() => {
    if (isInvoiceDataFetched || isCartItemDataFetched) {
      const ids = invoiceCartItems.map((item) => item?._id);
      const filteredItems = cartItems.map((item) => {
        //|| item?.cart_item?.supplier_invoice is for past data
        if (ids.includes(item.cart_item?._id) || item?.cart_item?.supplier_invoice || item?.cart_item?.has_invoice || recordedCartItemIDs?.includes(item?.cart_item?._id)) {
          return {
            ...item,
            blacklist: true,
          };
        }
        return item;
      });
      setCartItems(filteredItems);
      setIsCartItemDataFetched(false);
      setisInvoiceDataFetched(false);
    }
  }, [invoiceCartItems, isInvoiceDataFetched, isCartItemDataFetched]);

  useEffect(() => {
    setLoading(true);
    if (vendor?._id) {
      
      fetchRecordedItemsbyVendor(vendor?._id)
      .then((res) => {
        setRecordedCartItemIDs(res.data)
      })

      fetchSupplierInvoiceItemsbyVendor(vendor?._id, user?.additional_vendors?.map(v => v?._id) || [])
        .then((res) => {
          setCartItems(res.data);
          setIsCartItemDataFetched(true);
          setLoading(false);
        })
        .catch((err) => console.warn(err));
    }
  }, [vendor]);

  useEffect(() => {
    if (handle !== 'new') {
      setCode(handle);
      form.setFieldsValue({ code: handle });
    }
  }, [handle]);

  const handleFileUpload = async () => {
    try {
      const fileLinks = await handleUploadImages();
      await fetchUpdate(code, { images: fileLinks });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (code && handle === 'new') {
      // Use the updated value of code
      handleFileUpload();
    }
  }, [code]);

  useEffect(() => {
    if (cartItems.length > 0) {
      const sortedItems = [...cartItems].sort((a, b) => {
        const dateA = a?.cart_item?.order_addition_date || a.payment_at;
        const dateB = b?.cart_item?.order_addition_date || b.payment_at;

        if (sort === -1) {
          return new Date(dateB) - new Date(dateA);
        }
        return new Date(dateA) - new Date(dateB);
      });
      setCartItems(sortedItems);
    }
  }, [sort, cartItems.length]);

  return (
    <div className="invoices-container">
      <Form
        form={form}
        className="invoice-form"
        onFinish={handleSubmit}
        layout="vertical"
      >
        <div className="header-save">
          <div>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: '10px' }}
              disabled={loading}
            >
              {t('buttons.Save')}
            </Button>
            <Button type="secondary" onClick={() => navigate(-1)}>
              {t('buttons.Cancel')}
            </Button>
          </div>
        </div>
        <Form.Item label={t('fields.invoice.Code')} name="code">
          <Input
            value={code}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            disabled
          />
        </Form.Item>
      </Form>
      <div className="inputs-invoice">
        <div className="add-invoice-image-container">
          <h3 className="invoice-title">
            {t('fields.invoice.Add Invoice Image')}
          </h3>
          <FileViewerUploader fileList={fileList} onFileListChange={handleFileListChange} />
          {(fileList?.length > 0) ? null
            : <Alert style={{ marginTop: '5px' }} message={t('fields.invoice.You can Add Files of your Invoice from here')} type="info" />}
        </div>
        <div className="all-invoice-items-container">
          <h3 className="invoice-title">{t('fields.invoice.Invoice Items')}</h3>
          {invoiceCartItems?.length > 0
            ? invoiceCartItems?.map((item, index) => (
              <EditInvoiceItem
                item={item}
                index={index}
                _id={item?._id}
                unique_order_id={item?.unique_order_id}
                supplier_stock_code={item?.supplier_stock_code}
                name={item?.name}
                name_tr={item?.name_tr}
                names= {item?.names}
                payment_at={item?.payment_at}
                quantity={item?.quantity}
                item_count={item?.item_count}
                main_image={item?.main_image}
                key={item?._id}
                buttonFunctionality={handleRemoveProductFromInvoice}
                buttonType="Remove"
              />
            ))
            : <Alert message={t('fields.invoice.You can Add Order Products from below to your invoice')} type="info" />}
        </div>
        <div className="all-cart-items-container">
          <h3 className="invoice-title">
            {t('fields.invoice.Add Invoice Items')}
            <div className="all-cart-items-filter">
              <Select label="Sırala" className="form-bar-select" placeholder="Sırala" defaultValue={-1} onChange={setSort}>
                <Option key="new-to-old" value={-1}>{t('sort.New to Old')}</Option>
                <Option key="old-to-new" value={1}>{t('sort.Old to New')}</Option>
              </Select>
            </div>
          </h3>
          {loading && <Spin tip={t('placeholders.Loading Products...')} />}
          <div className="all-cart-items-sub-container">
            {cartItems?.map((item, index) => (item?.blacklist ? null
              : (
                <EditInvoiceItem
                  item={item}
                  index={index}
                  _id={item?.cart_item?._id}
                  unique_order_id={item?.unique_order_id}
                  supplier_stock_code={item?.cart_item?.supplier_stock_code}
                  name={item?.cart_item?.names['en']}
                  name_tr={item?.cart_item?.names['tr']}
                  names={item?.cart_item?.names}
                  payment_at={item?.payment_at}
                  order_addition_date={item?.cart_item?.order_addition_date}
                  quantity={item?.cart_item?.quantity}
                  item_count={item?.cart_item?.item_count}
                  main_image={item?.cart_item?.product_image}
                  key={item?.cart_item?._id}
                  buttonFunctionality={handleAddProductToInvoice}
                  buttonType="Add"
                />
              )
            ))}
          </div>
        </div>

      </div>
    </div>
  );
}

export default EditInvoice;
