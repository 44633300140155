import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_BASE_ENDPOINT;

export const fetchReturns = createAsyncThunk('returns/fetchReturns', async () => {
    const result = await axios.get(`${url}/supplier-authority/return-values`);

    return result?.data;
});

export const fetchAuthorityConfigs = createAsyncThunk('returns/fetchAuthorityConfigs', async () => {
    const result = await axios.get(`${url}/supplier-authority/get-configs`);

    return result?.data;
});

export const fetchReturnsByReason = createAsyncThunk('returns/fetchReturnsByReason', async ({ vendorId, selectedRegion }) => {
    console.log(selectedRegion);
    const result = await axios.post(`${url}/supplier-authority/returns-by-reason`, {
        vendorId: vendorId,
        region: selectedRegion
    });

    return result?.data;
});

const returnSlice = createSlice({
    name: 'return',
    initialState: {
        returns: {},
        allReturns: [],
        reasonCounts: {},
        authorityConfigs: [],
        status: {
            fetchReturns: 'idle',
            fetchAuthorityConfigs: 'idle',
            fetchReturnsByReason: 'idle'
        },
        error: null,
    },
    reducers: {},
    extraReducers: {
        [fetchReturns.pending]: (state) => {
            state.status.fetchReturns = 'pending';
        },
        [fetchReturns.fulfilled]: (state, action) => {
            state.status.fetchReturns = 'fulfilled';
            state.returns = action.payload;
        },
        [fetchReturns.rejected]: (state, action) => {
            state.status.fetchReturns = 'rejected';
            state.error = action.error.message;
        },

        [fetchAuthorityConfigs.pending]: (state) => {
            state.status.fetchAuthorityConfigs = 'pending';
        },
        [fetchAuthorityConfigs.fulfilled]: (state, action) => {
            state.status.fetchAuthorityConfigs = 'fulfilled';
            state.authorityConfigs = action.payload;
        },
        [fetchAuthorityConfigs.rejected]: (state, action) => {
            state.status.fetchAuthorityConfigs = 'rejected';
            state.error = action.error.message;
        },

        [fetchReturnsByReason.pending]: (state) => {
            state.status.fetchReturnsByReason = 'pending';
        },
        [fetchReturnsByReason.fulfilled]: (state, action) => {
            state.status.fetchReturnsByReason = 'fulfilled';
            state.allReturns = action.payload.allReturns;
            state.reasonCounts = action.payload.reasonCounts;
        },
        [fetchReturnsByReason.rejected]: (state, action) => {
            state.status.fetchReturnsByReason = 'rejected';
            state.error = action.error.message;
        }
    },
});

export default returnSlice.reducer;